export const getPostMapped = (sanityPost) => {
  const categories = getCategories(sanityPost.categories);
  const authors = getAuthors(sanityPost.author);
  return {
    id: sanityPost._id,
    title: sanityPost.title,
    publishedAt: sanityPost.publishedAt,
    updatedAt: sanityPost._updatedAt,
    seo: {
      title: sanityPost.seo.title,
      description: sanityPost.seo.desc,
      canonicals: sanityPost.seo.canonical,
      robots: sanityPost.seo.seo_robots,
    },
    slug: sanityPost.slug.current,
    sources: sanityPost.sources,
    authors,
    imagen: sanityPost.mainImage,
    imagen_alt: sanityPost.mainImage_alt,
    categories,
    content: sanityPost._rawBody,
  };
};

function getCategories(data) {
  const categories = [];
  if (Array.isArray(data)) {
    data.forEach((cat) => {
      const _cat = {
        id: cat._id,
        title: cat.title,
        slug: cat.slug.current,
        description: cat.description,
      };
      categories.push(_cat);
    });
  }
  return categories;
}

function getAuthors(data) {
  const authors = [];
  if (Array.isArray(data)) {
    data.forEach((author) => {
      const _author = {
        id: author._id,
        name: author.name,
        photo: author.photo,
        biography: author._rawBio,
        roles: author.roles,
        socials: author.socials
      };
      authors.push(_author);
    });
  }

  return authors;
}
