import { graphql, Link } from "gatsby";
import { PageContext } from "gatsby/internal";
import * as React from "react";

import Pagination from "../../components/Pagination/Pagination";
import { SEO } from "../../components/SEO/SEO";
import { Post } from "../../contexts/blog/domain/post";
import { getCategoryMapped } from "../../contexts/blog/infrastructure/categorySanityDataMapper";
import { getPostMapped } from "../../contexts/blog/infrastructure/postSanityDataMapper";
import PostList from "../../views/blog/PostList/PostList";
import Hero from "../../views/shared/hero/Hero";
import Layout from "../../views/shared/layout/layout";

interface CategoryPageContext extends PageContext {}

const PageCagetory: React.FC<CategoryPageContext> = (
  props: CategoryPageContext
) => {
  const posts: Post[] = props.data.allSanityPost.nodes.map((_post) =>
    getPostMapped(_post)
  );
  const category = getCategoryMapped(props.data.sanityCategory);
  const categories = [];
  props.data.allSanityCategory.nodes.forEach((_category) => {
    categories.push(getCategoryMapped(_category));
  });

  const categories_render = [];
  categories.forEach((category) => {
    categories_render.push(
      <Link
        key={`/${category.slug}/`}
        to={`/${category.slug}/`}
        className={"a-category-link"}
      >
        {category.title}
      </Link>
    );
  });

  const photo = category.image
    ? category.image.asset.gatsbyImageData.images.fallback.src
    : posts[Math.floor(Math.random() * (posts.length - 1))].imagen.asset
        .gatsbyImageData.images.fallback.src;
  return (
    <Layout>
      <SEO
        title={category.title}
        description={category.description}
        canonical={category.slug}
      />
      <Hero
        title={category.title}
        photo={photo}
        photo_alt={category.background_alt}
        color={"#A3BCC4"}
      >
        {categories_render}
      </Hero>
      <main id={"content-posts"}>
        <PostList posts={posts} />
        <Pagination
          nextPagePath={props.pageContext.nextPagePath}
          numberOfPages={props.pageContext.numberOfPages}
          pageNumber={props.pageContext.humanPageNumber}
          previousPagePath={props.pageContext.previousPagePath}
        />
      </main>
    </Layout>
  );
};

export default PageCagetory;

export const query = graphql`
  query CategoryQuery($id: String, $skip: Int!, $limit: Int!) {
    sanityCategory(id: { eq: $id }) {
      slug {
        current
      }
      title
      _id
      description
      image {
        _key
        _type
        asset {
          _id
          gatsbyImageData
        }
      }
      image_alt
    }
    allSanityCategory {
      nodes {
        slug {
          current
        }
        title
        _id
        description
      }
    }
    site {
      siteMetadata {
        projectId
        dataset
      }
    }
    allSanityPost(
      skip: $skip
      limit: $limit
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        ...Post
      }
    }
  }
`;
