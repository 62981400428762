import "./post-list.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Post } from "../../../contexts/blog/domain/post";

interface Props extends PropsWithChildren<any> {
  posts: Post[];
}

const PostList: React.FC<Props> = (props: Props) => {
  if (props.posts.length === 0)
    return (
      <div>
        <p className={"is-text-center"}>
          {" "}
          No hay post en esta categoría todavía
        </p>
      </div>
    );
  const posts_render = [];
  props.posts.forEach((post) => {
    const categories_render = [];
    post.categories.forEach((category) => {
      categories_render.push(
        <span key={`${post.id}+${category.slug}`}>#{category.title}</span>
      );
    });
    posts_render.push(
      <div key={post.id} className={"m-card"}>
        <a href={`/${post.slug}/`}>
          <SanityImage
            {...post.imagen}
            alt={post.title}
            className={"a-image"}
          />
        </a>
        <div className={"m-card__info"}>
          <a href={`/${post.slug}/`}>
            <h2 className={"m-card__title"}>{post.title}</h2>
          </a>
          <div className={"flex-space-between"}>
            {categories_render}
            <Link className={"a-button"} to={`/${post.slug}/`}>
              Ver más
            </Link>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={"o-blog-post"}>
      <div
        className={
          "container grid-x2 grid-x2--blog grid-x2--break-pro grid-x2--large-gap"
        }
      >
        {posts_render}
      </div>
    </div>
  );
};

export default PostList;
