export const getCategoryMapped = (sanityCategory) => {
  const image = getImage(sanityCategory);
  return {
    id: sanityCategory._id,
    title: sanityCategory.title,
    slug: sanityCategory.slug.current,
    description: sanityCategory.description,
    image
  };
};

function  getImage(data){
  if(data.image){
    return data.image;
  } else {
    return null;
  }
}